import React from 'react';

export const ALUMINIUM_CAP_7016 = 'ral7016';
export const ALUMINIUM_CAP_9005 = 'ral9005';

const AluminiumCap = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`aluminiumCap__${formId}`}>Zaślepka aluminiowa</label>
    <select
      className="form-control"
      id={`aluminiumCap__${formId}`}
      name="aluminiumCap"
      onChange={onChange}
      value={value}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={ALUMINIUM_CAP_7016}>Zaślepka RAL 7016</option>
      <option value={ALUMINIUM_CAP_9005}>Zaślepka RAL 9005</option>
    </select>
  </div>
);

export default AluminiumCap;
